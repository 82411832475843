@import "../../../bower_components/bootstrap/less/bootstrap";
@import "../../../bower_components/font-awesome/less/font-awesome";

@import "layout";
@import "panel-tabs";

.thumbnail.right-caption {
  > img {
    float: left;
    margin-right: 9px;
  }

  > .caption {
    padding: 4px;
  }

  float: left;
}

.mouse-pointer {
  cursor: pointer;
}

.text-editable {
  border-bottom: 1px blue dashed;
}

.fa-vs-group:before {
  content: @fa-var-archive;
  //color: @brand-primary;
}

.fa-vs-category:before {
  content: @fa-var-list-alt;
  //color: @brand-info;
}

.fa-vs-collection:before {
  content: @fa-var-object-group;
  //color: @brand-success;
}

.fa-vs-family:before {
  content: @fa-var-book;
  //color: @brand-warning;
}

.fa-vs-part:before {
  content: @fa-var-cube;
  //color: @brand-danger;
}

.fa-vs-attribute:before {
  content: @fa-var-tag;
  //color: @brand-danger;
}

.fa-vs-attributes:before {
  content: @fa-var-tags;
  //color: @brand-danger;
}